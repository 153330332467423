﻿import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';

import { User, Task } from '../_models';
import { UserService } from '../_services';
import { UndialService } from '../_services';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { AngularFileUploaderComponent } from '../angular-file-uploader/angular-file-uploader.component';
import { AuthService } from '../auth/src/utils';


@Component({
templateUrl: 'home.component.html', 
styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  public isLoading = false;

  currentUser: firebase.User;
  users: User[] = [];
  loading: boolean = false;
  selectedUndial:string = 'Sql';
  updateLog;
  public _opened: boolean = true;
  tasks: Task[] = [];

  public _toggleOpened(): void {
    this._opened = !this._opened;
  }

  informaticaSubscription: Subscription;

  afuConfig = {
    multiple: true,
    formatsAllowed: ".sql, .txt, .zip",
    maxSize: "10",
    uploadAPI: {
      url: `${environment.ENDPOINT_SQL}/file/upload`
    },
    theme: "dragNDrop",
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: false,
    uploadBtnText: 'Upload and Process'
  };

  afuConfig1 = {
    multiple: true,
    formatsAllowed: ".xml",
    maxSize: "10",
    uploadAPI: {
      url: `${environment.ENDPOINT_XML}/file/upload`
    },
    theme: "dragNDrop",
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: false,
    uploadBtnText: 'Upload and Process'
  };

  constructor(private userService: UserService, private undialService: UndialService,
    private router: Router, private spinner: NgxSpinnerService,
    private authService: AuthService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    // this.initUndial();
    // this.loadAllUsers();
    //this.loadTasks();

    this.isLoading = true;
    this.authService.getUser().subscribe((user) => {
      this.currentUser = user;
      this.isLoading = false;
    });
  }

  DocUpload(event){
    if(event.status == 200) {
      // Reload task list
      //this.loadTasks();
      if(this.selectedUndial == 'Informatica'){
        this.checkExistingTask();
      }
    }
  }

  private initUndial() {
    this.undialService.initUndial().subscribe(data => {
    });
  }

  onUndialChange(undial){
    this.undialService.setUndialType(undial);
    this.afuConfig.uploadAPI.url = undial == 'Sql'?`${environment.ENDPOINT_SQL}/file/upload`:`${environment.ENDPOINT_XML}/file/upload`;
  }

  onProcess() {
    this.loading = true;
    this.spinner.show();
    this.undialService.processScripts().subscribe(data => {
      this.loading = false;
      this.spinner.hide();
      this.router.navigate(['/output']);
    });
  }

  checkExistingTask(){
    if(this.tasks && this.tasks.length >0){
      return true
    }
    else{
      return false
    }
  }

  onTaskRowClick(){
    this.router.navigate(['/output'],{ queryParams: { task:1}});
  }

  logout(){
    this.authService.logout();
  }

  GraphNavigate(){
    this.router.navigate(['/output']);
  }

  informaticaNavigate(){
    this.router.navigate(['/informatica']);
  }

}
