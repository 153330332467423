import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.authService.isAuthorized().pipe(
            tap((isAuthorized) => {
                if (!isAuthorized) {
                    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
                }
            })
        );
    }
}
