// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ENDPOINT_SQL: 'https://deloitte-test.apigee.net/dfte-undial/sql',
  ENDPOINT_XML: 'https://deloitte-test.apigee.net/dfte-undial/xml',
  // ENDPOINT_SQL: 'http://localhost:5000/sql',
  // ENDPOINT_XML: 'http://localhost:5001/xml',

  API_KEY: 'AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU',
  AUTH_DOMAIN: 'deloitte-test.apigee.net',
  PROJECT_ID: 'dcsptest',

  AUTH_PROVIDER: 'oidc.azure',
  REDIRECT_URI: '/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
