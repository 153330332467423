import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { User } from '../_models';
import { AuthService } from '../auth/src/utils';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FeaturesComponent implements OnInit {

  public _opened: boolean = true;
  currentUser: firebase.User;
  public isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.authService.getUser().subscribe((user) => {
      this.currentUser = user;
      this.isLoading = false;
    });
  }

  public _toggleOpened(event: any): void {
    event.preventDefault();
    this._opened = !this._opened;
  }

  GraphNavigate(){
    this.router.navigate(['/output']);
  }

  public logout() {
    this.authService.logout();
  }

}
