import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const endpointSQL = environment.ENDPOINT_SQL;
const endpointXML = environment.ENDPOINT_XML;

@Injectable()
export class UndialService {

  private _undialType:string = '';
  constructor(private http: HttpClient) { }


  getAllTargetTables(userId, taskId) {
    return this.http.get(`${endpointSQL}/user/${userId}/lineage/${taskId}/target_tables`)
  }

  getGraphData(tableName) {
    return this.http.get(`${endpointSQL}/lineage/${tableName}`);
  }

  getTablesList() {
    return this.http.get(`${endpointSQL}/table-list`);
  }

  processScripts() {
    return this.http.get(`${endpointSQL}/process`)
  }

  initUndial() {
    return this.http.get(`${endpointSQL}/init`)
  }

  // getGraphData(userId, taskId, tableName) {
  //   return this.http.get(`${endpoint}/user/${userId}/graph/${taskId}/${tableName}`)
  // }

  getTableMapping() {
    return this.http.get(`${endpointSQL}/get_table_mapping`);
  }

  getColumnMapping() {
    return this.http.get(`${endpointSQL}/get_column_mapping`)
  }

  downloadTableMapping() {
    return this.http.get(`${endpointSQL}/downloadTableMapping`)
  }

  getTaskSummary(userId, taskId) {
    return this.http.get(`${endpointSQL}/user/${userId}/summary/${taskId}`)
  }

  getInformaticaDataFrame() {
    return this.http.get(`${endpointXML}/fetch_dataframe`)
  }

  setUndialType(undial){
    this._undialType = undial;
  }

  getUndialType(){
    return this._undialType;
  }

}
