import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router'
import { User } from '../_models';
import { UserService } from '../_services';
import { UndialService } from '../_services';
import { NgxSpinnerService } from 'ngx-spinner';
import * as d3 from 'd3';
import { ExcelService } from '../excel.service';
import { AuthService } from '../auth/src/utils';


@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OutputComponent implements OnInit {
  public isLoading = false;

  currentUser: firebase.User;
  targetTables: string[];
  users: {};
  rowData: any;
  rowData2: any;
  informaticaDataFrame:any;
  rowDataSummary: any;
  taskId:any;
  //tables: [];
  tableSelected:Boolean = false;
  public _opened: boolean = true;
  sqlType:string = '';

  columnDefs = [
    // { headerName: 'Script ID', field: 'script_id' },
    { headerName: 'Script Name', field: 'script_name' },
    { headerName: 'Step Type', field: 'step_type' },
    { headerName: 'Target Table', field: 'target_table' },
    { headerName: 'Source Table', field: 'source_tables' }
  ];



  columnDefs2 = [
    // { headerName: 'Script ID', field: 'step_id' },
    { headerName: 'Script Name', field: 'script_name' },
    { headerName: 'Source Type', field: 'source_type' },
    { headerName: 'Source Table/File', field: 'source_table_file' },
    { headerName: 'Source Field/Column', field: 'source_field_column' },
    { headerName: 'Target Type', field: 'target_type' },
    { headerName: 'Target Table/File', field: 'target_table_file' },
    { headerName: 'Target Field/Column', field: 'target_field_column' },
    { headerName: 'Transformation Logic', field: 'transformation_logic_vaue'},
    { headerName: 'Expression Transformation', field: 'expression_transformation'},
    { headerName: 'Literal or HardCoded Value', field: 'literal_or_hardcoded_value'},
    { headerName: 'Join Type', field: 'join_type'},
    { headerName: 'Join Table', field: 'join_table'},
    { headerName: 'Join Condition', field: 'join_condition'},
    { headerName: 'Filter Condition', field: 'filter_condition'},
    { headerName: 'Comments', field: 'comments'},
    { headerName: 'DML Type', field: 'dml_type'},
  ];

  dataframeDefs = [
    // { headerName: 'Script ID', field: 'step_id' },
    // { headerName: 'Mapping Index', field: 'mapping_index' },
    { headerName: 'Target Index', field: 'target_index' },
    { headerName: 'Mapping Name', field: 'mapping_name' },
    { headerName: 'Source Instance Type', field: 'source_instance_type' },
    { headerName: 'Source Instance', field: 'source_instance' },
    // { headerName: 'Union Condition', field: 'union_condition' },
    { headerName: 'Source Location', field: 'source_location' },
    { headerName: 'Source Field', field: 'source_field'},
    { headerName: 'Target Instance Type', field: 'target_instance_type'},
    { headerName: 'Target Instance', field: 'target_instance'},
    { headerName: 'Target Field', field: 'target_field'},
    { headerName: 'Port Type', field: 'port_type'},
    { headerName: 'Expression', field: 'expression'},
    { headerName: 'Source Override SQL', field: 'source_override_sql'},
    { headerName: 'Pre Post SQL', field: 'pre_post_sql'},
    { headerName: 'Joiner Extract', field: 'joiner_extract'},
    { headerName: 'Filter Expression', field: 'filter_expression'}
  ];

  columnDefSummary = [
    { headerName: 'Script Name', field: 'script_name'},
    { headerName: '# of INSERT statements', field: 'insert_statements'},
    { headerName: 'Status', field: 'status'},
    { headerName: 'Comments', field: 'comments'}
  ];



  constructor(
    private userService: UserService, 
    private undialService: UndialService,
    private spinner: NgxSpinnerService, private route: ActivatedRoute, private excelService: ExcelService,
    private router: Router,
    private authService: AuthService
  ) { }


  public _toggleOpened(event:any): void {
    event.preventDefault();
    this._opened = !this._opened;
  }

  private loadD3Tree(treeData: any) {
    //treeData = {"name":"A","children":[{"name":"B"},{"name":"C","children":[{"name":"hello"}]}]};

    // Set the dimensions and margins of the diagram
    var margin = { top: 20, right: 90, bottom: 30, left: 90 },
      width = 1150 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    d3.selectAll("#d44tree").selectAll("svg").remove();
    var svg = d3.selectAll("#d44tree").append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .call(d3.zoom()
        .scaleExtent([1 / 2, 8])
        .on("zoom", function() {
          svg.attr("transform", d3.event.transform);
        }))
      .append("g")
      .attr("transform", "translate("
        + (margin.right) + "," + margin.top + ")");

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 1e-6);

    var i = 0,
      duration = 750,
      root;

    // declares a tree layout and assigns the size
    var treemap = d3.tree().size([height, width]);

    // Assigns parent, children, height, depth
    root = d3.hierarchy(treeData, function(d) { return d.children; });

    root.x0 = height / 2;
    root.y0 = 0;

    var collapse = (d) => {
      // console.log('Inside collapse');
      if (d.children) {
        d._children = d.children
        if (d._children && d._children.length > 0) {
          d._children.forEach(collapse);
        }
        d.children = null
      }
    };

    // Collapse after the second level
    root.children.forEach(function(d) {
      collapse(d);
    });


    this.update(div, duration, i, svg, treemap, root, root);
  }

  private collapse(d) {
    if (d.children) {
      d._children = d.children
      if (d.children) {
        d._children.forEach(this.collapse(d.children))
      }
      d.children = null
    }
  }

  private update(div, duration, i, svg, treemap, root, source) {

    // Assigns the x and y position for the nodes
    var treeData = treemap(root);

    // Compute the new tree layout.
    var nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    // Normalize for fixed-depth.
    nodes.forEach(function(d) { d.y = d.depth * 300 });

    // ****************** Nodes section ***************************

    // Update the nodes...
    var node = svg.selectAll('g.node')
      .data(nodes, function(d) { return d.id || (d.id = ++i); });

    // Enter any new modes at the parent's previous position.
    var nodeEnter = node.enter().append('g')
      .attr('class', 'node')
      .attr("transform", function(d) {
        return "translate(" + source.y0 + "," + source.x0 + ")";
      })
      .on('click', (d) => { this.click(div, duration, i, svg, treemap, root, d) });

    // Add Circle for the nodes
    nodeEnter.append('circle')
      .on("mouseover", mouseover)
      .on("mousemove", function(d) { mousemove(d); })
      .on("mouseout", mouseout)
      .attr('class', 'node')
      .attr('r', 1e-6)
      .style("fill", function(d) {
        return d._children ? "lightsteelblue" : "#fff";
      });


    // Add labels for the nodes
    nodeEnter.append('text')
      .attr("dy", "-.45em")
      .attr("x", function(d) {
        return d.children || d._children ? -13 : 13;
      })
      .attr("text-anchor", function(d) {
        return d.children || d._children ? "end" : "start";
      })
      .text(function(d) { return d.data.name; });


    function mouseover() {
      // div.transition()
      // .duration(300)
      // .style("opacity", 1);
    }

    function mousemove(d) {
      // div
      // .text(d.data.type)
      // .style("left", (d3.event.pageX +8 ) + "px")
      // .style("top", (d3.event.pageY+15) + "px");
    }

    function mouseout() {
      div.transition()
        .duration(300)
        .style("opacity", 1e-6);
    }

    // UPDATE
    var nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate.transition()
      .duration(duration)
      .attr("transform", function(d) {
        return "translate(" + d.y + "," + d.x + ")";
      });

    // Update the node attributes and style
    nodeUpdate.select('circle.node')
      .attr('r', 10)
      .style("fill", function(d) {
        return d._children ? "lightsteelblue" : "#fff";
      })
      .attr('cursor', 'pointer');


    // Remove any exiting nodes
    var nodeExit = node.exit().transition()
      .duration(duration)
      .attr("transform", function(d) {
        return "translate(" + source.y + "," + source.x + ")";
      })
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select('circle')
      .attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select('text')
      .style('fill-opacity', 1e-6);

    // ****************** links section ***************************

    // Update the links...
    var link = svg.selectAll('path.link')
      .data(links, function(d) { return d.id; });

    // Enter any new links at the parent's previous position.
    var linkEnter = link.enter().insert('path', "g")
      .attr("class", "link")
      .attr('d', function(d) {
        var o = { x: source.x0, y: source.y0 }
        return diagonal(o, o)
      });

    // UPDATE
    var linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate.transition()
      .duration(duration)
      .attr('d', function(d) { return diagonal(d, d.parent) });

    // Remove any exiting links
    var linkExit = link.exit().transition()
      .duration(duration)
      .attr('d', function(d) {
        var o = { x: source.x, y: source.y }
        return diagonal(o, o)
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function(d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // Creates a curved (diagonal) path from parent to the child nodes
    function diagonal(s, d) {

      var path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`

      return path
    }


  }

  // Toggle children on click.
  private click(div, duration, i, svg, treemap, root, d) {
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }
    this.update(div, duration, i, svg, treemap, root, d);
  }

  ngOnInit() {
    this.isLoading = true;
    this.authService.getUser().subscribe((user) => {
      this.currentUser = user;
      this.isLoading = false;
    });

    this.sqlType = this.route.routeConfig.path;
    if(this.sqlType == 'informatica'){
      this.undialService.getInformaticaDataFrame().subscribe(data =>{
        this.informaticaDataFrame = data['output_dataframe'];
      })
    }

   // this.taskId = this.route.snapshot.queryParams['task']
    // if(this.taskId) {
    //   // task id present - Load table and column lineage
    //   this.spinner.show();
    //   this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //   console.log(this.currentUser);
    //   //this.loadAllTargetTables();
    //   //this.loadTableMapping();
    //   //this.loadColumnMapping();
    //   //this.loadTaskSummary();
    //   this.spinner.hide();
    // }
    // else{
    //   // No Task Id present - redirect to home page
    //   this.router.navigate(['/']);
    // }\
      this.targetTables = [];
      this.spinner.show();
      this.undialService.getTablesList().subscribe(res => {
        this.targetTables = res['tables-list'];
        this.spinner.hide();

      });
  }

  loadTableMapping() {
    this.undialService.getTableMapping().subscribe(data => {
      this.rowData = data['table_mapping'];
    })
  }

  loadColumnMapping() {
    this.undialService.getColumnMapping().subscribe(data => {
      this.rowData2 = data['column_mapping'];
    })
  }

  onTableClick(table: any) {
   // if(table){
     this.tableSelected = true;
      this.spinner.show();
      this.undialService.getGraphData(table).subscribe(treeData => {
        this.removeDups(null, treeData)
        this.loadD3Tree(treeData);
        this.spinner.hide();
      });
    //}
  }




  private loadAllUsers() {
    // this.userService.getAll().pipe(first()).subscribe(users => {
    //   this.users = users;
    // });
  }

  private loadAllTargetTables() {
    this.targetTables = [];
  }

  downloadTableMapping() {
    this.undialService.downloadTableMapping().subscribe((data:any) => this.downloadFile(data)),//console.log(data),
      error => console.log("Error downloading the file."),
      () => console.info("OK");
  }

  downloadFile(data: Response) {
    var blob = new Blob([data], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  exportTableMapping(): void {
    //let header = ['step_id', 'script_name', 'step_type', 'source_table', 'target_table']
    let header = ['script_name', 'step_type','target_table','source_tables'];
    this.excelService.exportAsExcelFile(this.rowData, 'table-mapping', header);
  }

  exportColumnMapping(): void {
    // let header = ['script_name', 'target_table', 'target_column', 'source_table', 'source_column',
    //               'union_condition', 'transformation_logic_vaue', 'expression_transformation', 'where_clause']
    let header = ['script_name','source_type','source_table_file', 'source_field_column', 'target_type', 'target_table_file', 
        'target_field_column', 'transformation_logic_vaue', 'expression_transformation', 'literal_or_hardcoded_value', 
        'join_type', 'join_table', 'join_condition', 'filter_condition', 'comments', 'dml_type'];
    this.excelService.exportAsExcelFile(this.rowData2, 'column-mapping', header);
  }

  isTargetTablesAvailable() {
    if(this.targetTables && this.targetTables.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  onGraphClick(){
    this.tableSelected = false;
    this.loadD3Tree(null);
    this.isTargetTablesAvailable();
  }  

  logout(){
    this.authService.logout();
  }

  generateGraph(table) {
    let treeData = {"name":"test", children:[{"name":"Hello"},{"name":"Hi"}]}
    this.loadD3Tree(treeData)
  }

  removeDups(parent, data) {
    if (data && data.children) {
      data.children.forEach(d => {
        this.removeDups(data, d);
      });
      if (data.children.length > 0) {
        data.children = this.removeDuplicates(data.children, "name");
      }

    }

  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  loadTaskSummary(){
    this.undialService.getTaskSummary(this.currentUser.uid, this.taskId).subscribe((data:any) => {
      this.rowDataSummary = data
    })
  }

}
