﻿import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule }    from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent }  from './app.component';
import { routing }        from './app.routing';

import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { AlertService, UserService, UndialService } from './_services';
import { HomeComponent } from './home';
import { RegisterComponent } from './register';
import { ExcelService } from './excel.service';

// import { AngularFileUploaderModule } from "angular-file-uploader";
import { SidebarModule } from 'ng-sidebar';
import { AgGridModule } from 'ag-grid-angular';
import { UploadComponent } from './upload/upload.component';
import { OutputComponent } from './output/output.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularFileUploaderComponent } from './angular-file-uploader/angular-file-uploader.component'
import { AiHubAuthModule } from './auth';
import { FirebaseOptions } from '@angular/fire';

import { FeaturesComponent } from './features/features.component'

import { environment } from '../environments/environment'

const firebaseConfiguration: FirebaseOptions = {
    apiKey: environment.API_KEY,
    authDomain: environment.AUTH_DOMAIN,
    projectId: environment.PROJECT_ID
};

@NgModule({
    imports: [
        BrowserModule,
        // AngularFileUploaderModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxSpinnerModule,
        routing,
        FormsModule,
        SidebarModule.forRoot(),
        AgGridModule.withComponents([]),
        AiHubAuthModule.forRoot({
            /** name of the auth provider for example */
            authProvider: environment.AUTH_PROVIDER,
            // authProvider: "oidc.azure",

            /** firebase options */
            firebase: firebaseConfiguration,
            /** go to this page after success login */
            redirectUri: environment.REDIRECT_URI
        })
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        RegisterComponent,
        UploadComponent ,
        OutputComponent ,
        AngularFileUploaderComponent ,
        FeaturesComponent ],
    providers: [
        AuthGuard,
        AlertService,
        UserService,
        UndialService,
        ExcelService
        // provider used to create fake backend
        // fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
