export class AuthModel {

    private authUser: any;

    private authToken: string;

    public set user(user: any) {
        this.authUser = user;
    }

    public get user(): any {
        return this.authUser;
    }

    public set token(token: string) {
        this.authToken = token;
    }

    public get token(): string {
        return this.authToken;
    }
}
