﻿import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home'; 
import { RegisterComponent } from './register';
import { UploadComponent } from './upload/upload.component';
import { OutputComponent } from './output/output.component';
import { FeaturesComponent } from './features/features.component';
import { AuthGuard } from './auth';

const appRoutes: Routes = [   
    { path: '', component: HomeComponent, canActivate: [AuthGuard]},
    { path: 'register', component: RegisterComponent, canActivate: [AuthGuard]},
    { path: 'output', component: OutputComponent, canActivate: [AuthGuard]},
    { path: 'informatica', component: OutputComponent, canActivate: [AuthGuard]},
    { path: 'table', component: UploadComponent , canActivate: [AuthGuard]},
    { path: 'features', component: FeaturesComponent , canActivate: [AuthGuard]},

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
