import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class SubscriptionInterceptor implements HttpInterceptor {

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const subscriptionId = localStorage.getItem('Subscription-ID');

        if (subscriptionId) {
            const req = request.clone({ setHeaders: { 'Subscription-ID': subscriptionId } });

            return next.handle(req);
        }

        return next.handle(request)
    }
}
