import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../utils';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthRedirectUri, AutoLoginToken } from '../../data';

@Component({
  selector: 'aihub-login',
  templateUrl: './login.html',
  host: {
    class: 'my-auto'
  },
  styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit {
  public userLoggedIn = false;
  public isLoading = false;

  constructor(
    @Inject(AuthRedirectUri) private authRedirectUri: string,
    @Inject(AutoLoginToken) private autoLogin: boolean,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  public ngOnInit() {
      this.isLoading = true;
    /**
     * todo rework with angular 9 and typescript 3.7 to
     * isAutologin = this.router.getCurrentNavigation()?.extras.state.autoLogin;
     */
    // const navigation = this.router.getCurrentNavigation();
    // if ( !navigation || navigation.extras.state.autoLogin !== false) {
    this.authService.isAuthorized().subscribe(authorized => {
        localStorage.setItem('Subscription-ID', this.activatedRoute.snapshot.params.sub_id);

        this.isLoading = false;
        if (authorized) {
            this.userLoggedIn = true;
            this.redirect();
            return;
        }

        if (this.autoLogin) {
            this.doLogin();
        }
    });
    // }
  }

  public doLogin() {
    this.performLogin();
  }

  private performLogin() {
    /** display message we will now login */
    this.authService
      .isAuthorized()
      .subscribe(loggedIn =>
        !loggedIn ? this.authService.login() : this.redirect()
      );
  }

  private redirect() {
    this.router.navigate([this.authRedirectUri]);
  }
}
