import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { LoginComponent } from "./ui";
import { AuthGuard } from "./utils";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
        LoginComponent
    ],
    entryComponents: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: "login",
                component: LoginComponent
            },
            {
                path: "login/:sub_id",
                component: LoginComponent
            }
        ])
    ],
    exports: [ RouterModule ],
    providers: [ AuthGuard ],
})
export class AuthRoutingModule {}
